export interface IEvent {
    isMaintenance: boolean
    isSetup: boolean
    name: string
    startDateTime: string
    endDateTime: string
    isCaptureState: boolean
}

interface Data {
    [key: string]: any // eslint-disable-line 
}

export const isUuid = (value: string) => {
    return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value);
};

export const min = (value: number, minValue: number) => value < minValue ? minValue : value;
export const max = (value: number, maxValue: number) => value > maxValue ? maxValue : value;

export const toFakeUTC = (input: Date) => {
    const utcDate = new Date(input);

    return new Date(new Date(new Date().setFullYear(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate())).setHours(utcDate.getUTCHours(), utcDate.getUTCMinutes(), utcDate.getUTCSeconds(), utcDate.getUTCMilliseconds()));
};

export const fromFakeUTC = (input: string) => {
    const utcDate = new Date(input);

    return new Date(new Date(new Date().setUTCFullYear(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate())).setUTCHours(utcDate.getHours(), utcDate.getMinutes(), utcDate.getSeconds(), utcDate.getMilliseconds()));
};

export const getEventState = (event: IEvent) => {
    if(event.isMaintenance) return "maintenance";
    if(event.isSetup)       return "setup";

    return event.name;
};

export const getEventDistribution = (events: IEvent[], [from, until]: [Date, Date]) => {
    return events
        .filter(x => new Date(x.startDateTime) <= until && new Date(x.endDateTime) >= from)
        .reduce((dest: Data, elem) => {
            const eventState = getEventState(elem);
            const end        = new Date(elem.endDateTime) > until ? until : new Date(elem.endDateTime);
            const start      = new Date(elem.startDateTime) < from ? from : new Date(elem.startDateTime);

            return ({
                ...dest,
                [eventState]: (dest[eventState] ?? 0) + (end.getTime() - start.getTime()) / 1000
            });
        }, {});
};

export const toDateString = (value: number) => {
    const hours   = Math.floor(value / 60 / 60);
    const minutes = Math.floor(value / 60) % 60;
    const seconds = Math.floor(value % 60);

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")} h`;
};
