import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useBranch, useTrigger, useTranslation } from "@circle/gestalt-app";
import { BusyIndicator } from "./BusyIndicator.jsx";
import { Icon, Sidebar, SidebarButton } from "@circle/kip-components";
import { sidebarButtonHighlightClassName } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import { useLocation } from "react-router-dom";

import styles from "./menu.module.scss";

const Menu = () => {
    const { trigger }    = useTrigger();
    const { translate }  = useTranslation();
    const location       = useLocation();
    const { globalBusy } = useBranch({
        globalBusy: ["counter", "global"]
    });
    const onClick = () => {
        trigger("clearShift");
    };

    const isAdditionalActive = location.pathname.includes("/runtime/") || location.pathname.includes("/performance/") || location.pathname.includes("/events/");

    return (
        <Sidebar className={styles.sidebar}>
            <NavLink
                id="overview"
                to="/overview"
                className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, isAdditionalActive && sidebarButtonHighlightClassName, styles.menuItem)}
                onClick={onClick}
                rel="noopener noreferrer"
            >
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Overview"/>
                    <Tooltip anchorSelect="#overview" effect="solid" place="right" content={translate("menu.overview.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }}/>
                </SidebarButton>
            </NavLink>
            <NavLink id="favorite" to="/favorite" className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} onClick={onClick} rel="noopener noreferrer" >
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Star"/>
                    <Tooltip anchorSelect="#favorite" effect="solid" place="right" content={translate("menu.favorite.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }}/>
                </SidebarButton>
            </NavLink>
            <NavLink id="settings-view" to="/admin" className={({ isActive }) => resolveClassNames(isActive && sidebarButtonHighlightClassName, styles.menuItem)} onClick={onClick} rel="noopener noreferrer" >
                <SidebarButton>
                    <Icon className="menu-icon" _icon="Settings"/>
                    <Tooltip anchorSelect="#settings-view" effect="solid" place="right" content={translate("menu.settings.tooltip")} style={{ backgroundColor: "#4F5358", fontSize: "12px" }}/>
                </SidebarButton>
            </NavLink>
            {
                globalBusy > 0 &&
                <BusyIndicator withContainer />
            }
        </Sidebar>
    );
};

export { Menu };
