import { useBranch, useTranslation, useTrigger } from "@circle/gestalt-app";
import { numberFormatter } from "../../../../../helper/numberFormatter";
import { BusyIndicator } from "../../../../BusyIndicator";
import { Icon, Modal } from "@circle/kip-components";
import { resolveClassNames } from "palstek";
import PropTypes from "prop-types";

import styles from "./eventsTableCard.module.scss";
import { InfiniteList } from "../../../../generic/InfiniteList";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePrevious } from "../../../../../hooks/previous";
import { EventForm } from "../../../../EventForm";

const EventsTableCard = ({ ...props }) => { // eslint-disable-line complexity
    const { translate }     = useTranslation();
    const { trigger }       = useTrigger();
    const params            = useParams();
    const [modal, setModal] = useState(false);
    const [event, setEvent] = useState({});

    const { sorting, options, events, metadata, loading, data, selectedPlant } = useBranch({
        options:       ["queryOptions"],
        sorting:       ["sortings", "events"],
        events:        ["events"],
        metadata:      ["metadata", "detail"],
        loading:       ["loadingViews", "runtimeEventList"],
        data:          ["clusterRuntimeOverview", "eventsList"],
        selectedPlant: ["selectedPlant"]
    });

    const optionsPrev = usePrevious(options);

    const onClose = () => {
        setModal(!modal);
        setEvent({});
    };

    const selectEvent = ev => {
        setEvent(ev);
        setModal(!modal);
    };

    const onFetch = () => {
        trigger("fetchEventsDetailList", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: false });
    };

    const onSort = val => {
        const inter = sorting.isOrderAsc ? "desc" : "asc";
        const order = val !== sorting.property ? "desc" : inter;

        trigger("fetchEventsDetailList", { plantId: params.plantId, orderKey: val, order, reset: true });
        trigger("setSorting", "events", val);
    };

    useEffect(() => {
        trigger("setSorting", "events", "startDateTime");
        trigger("fetchEventsDetailList", { plantId: params.plantId, orderKey: "startDateTime", order: "desc", reset: true });
    }, []);

    useEffect(() => {
        if(!events || !options || !optionsPrev) return;

        trigger("fetchEventsDetailList", { plantId: params.plantId, orderKey: sorting.property, order: sorting.isOrderAsc ? "asc" : "desc", reset: true });
    }, [options, events]);

    return (
        <div className={resolveClassNames(styles[props.type], styles.mainContainer)}>
            <div className={styles.headerTitle}>
                <span><b>{translate("dashboard.widgets.events")}</b></span>
            </div>
            <div className={styles.contentContainer}>
                {
                    loading &&
                    <BusyIndicator />
                }
                {
                    !loading &&
                    <>
                        <div className={resolveClassNames(styles.itemContainer, styles.headersContainer)}>
                            <div className={resolveClassNames(styles.clickable, styles.columnLarge)}>
                                <span className={styles.item} onClick={() => onSort("startDateTime")}><b>{translate("events.start.time")}</b></span>
                                {
                                    sorting.property === "startDateTime" &&
                                    <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </div>
                            <div className={resolveClassNames(styles.clickable, styles.columnLarge)}>
                                <span className={styles.item} onClick={() => onSort("name")}><b>{translate("element.name.header")}</b></span>
                                {
                                    sorting.property === "name" &&
                                    <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </div>
                            <div className={resolveClassNames(styles.clickable, styles.columnLarge)}>
                                <span className={styles.item} onClick={() => onSort("category")}><b>{translate("events.category")}</b></span>
                                {
                                    sorting.property === "category" &&
                                    <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </div>
                            <div className={resolveClassNames(styles.clickable, styles.column, styles.alignedRight)}>
                                <span className={styles.item} onClick={() => onSort("duration")}><b>{translate("overview.filter.duration")}</b></span>
                                {
                                    sorting.property === "duration" &&
                                    <Icon className={styles.filterArrow} _icon={sorting.isOrderAsc ? "ArrowUp" : "ArrowDown"} />
                                }
                            </div>
                        </div>

                        {
                            <InfiniteList
                                metadata={metadata}
                                className={styles.eventsList}
                                onFetch={onFetch}
                                element="div"
                                placeholder={<div><span>{translate("overview.no.content")}</span></div>}
                                loader={() => <span>Loading...</span>}
                            >
                                {
                                    (data ?? []).map((item, i) =>
                                        <div className={styles.itemContainer} key={i} role="button" onClick={() => selectEvent(item)}>
                                            <span className={styles.itemName}>
                                                {
                                                    new Date(item.startDateTime).toLocaleString("de", { timeZone: "UTC", month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" })
                                                }
                                            </span>
                                            <span className={styles.itemName}><b>{translate(item.name)}</b></span>
                                            <span className={styles.itemName}><b>{translate(item.category)}</b></span>
                                            <span className={styles.itemTime}><b>{numberFormatter(item.duration, 999999)} {translate("overview.minutes.short")}</b></span>
                                        </div>
                                    )
                                }
                            </InfiniteList>
                        }

                    </>
                }
            </div>
            <Modal
                title={translate("title.record.event")}
                isOpen={modal}
                isCloseable={false}
                onClose={onClose}
                className="event-modal"
            >
                <div id="event-form" className="flex-column">
                    <EventForm
                        isEditable={false}
                        onClose={onClose}
                        event={event}
                        selectedPlant={selectedPlant}
                        onSubmit={onClose}
                        plantId={selectedPlant?.id ?? params.plantId}
                        {...event}
                    />
                </div>
            </Modal>
        </div>
    );
};

EventsTableCard.propTypes = {
    type: PropTypes.string
};

export { EventsTableCard };
