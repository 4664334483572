import { getStartTime } from "../helper/shiftRange";
import { WorkingShift } from "./WorkingShift";

interface IDatePicker {
    from: Date,
    until: Date,
    name: string
}

const getEndOfHour = (date: Date) => {
    const hour         = date.getHours();
    const hours        = hour !== 23 ? date.getHours() + 1 : date.getHours();
    const minutes      = hour !== 23 ? 0 : 59;
    const seconds      = hour !== 23 ? 0 : 59;
    const milliseconds = hour !== 23 ? 0 : 999;

    return {
        hours,
        minutes,
        seconds,
        milliseconds
    };
};

const last7Days = () => {
    const currentDate = new Date();

    currentDate.setUTCDate(currentDate.getUTCDate());

    return {
        from:  new Date(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0, 0)),
        until: new Date(currentDate.setUTCHours(23, 59, 59, 999)),
        name:  "last7Days"
    };
};

const today = () => {
    const currentDate = new Date();

    const { hours, minutes, seconds, milliseconds } = getEndOfHour(currentDate);

    return {
        from:  new Date(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate() + 1).setUTCHours(0, 0, 0, 0)),
        until: new Date(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate() + 1).setUTCHours(hours, minutes, seconds, milliseconds)),
        name:  "today"
    };
};

const yesterday = () => {
    const now = new Date();
    const dayAgo = new Date(now);

    dayAgo.setDate(now.getDate() - 1);

    return {
        from:  new Date(dayAgo.setUTCHours(0, 0, 0, 0)),
        until: new Date(dayAgo.setUTCHours(23, 59, 59, 999)),
        name:  "yesterday"
    };
};

const thisMonth = () => {
    const currentDate = new Date();

    return {
        from:  new Date(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 2).setUTCHours(0, 0, 0, 0)),
        until: new Date(new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 1).setUTCHours(23, 59, 59, 999)),
        name:  "thisMonth"
    };
};

const thisYear = () => {
    const currentDate = new Date();

    return {
        from:  new Date(new Date(currentDate.getUTCFullYear(), 0, 2).setUTCHours(0, 0, 0, 0)),
        until: new Date(new Date(currentDate.getUTCFullYear() + 1, 0, 1).setUTCHours(23, 59, 59, 999)),
        name:  "thisYear"
    };
};

const overall = () => {
    const year = new Date().getUTCFullYear();

    return {
        from:  new Date(new Date("2020-01-01").setUTCHours(0, 0, 0, 0)),
        until: new Date(new Date(`${year}-12-31`).setUTCHours(23, 59, 59, 999)),
        name:  "overall"
    };
};

const shift = (shifts: Array<WorkingShift>) => {
    const fromStartTime = getStartTime(shifts, "shift") || [];

    return {
        from:  new Date(fromStartTime[0]),
        until: new Date(fromStartTime[1]),
        name:  "shift"
    };
};

const lastShift = (shifts: Array<WorkingShift>) => {
    const fromStartTime = getStartTime(shifts, "lastShift") || [];

    return {
        from:  new Date(fromStartTime[0]),
        until: new Date(fromStartTime[1]),
        name:  "lastShift"
    };
};

const evaluateType = (from: number, until: number) => { // eslint-disable-line complexity
    const { from: overallFrom, until: overallUntil } = overall();
    const { from: todayFrom, until: todayUntil } = today();
    const { from: thisYearFrom, until: thisYearUntil } = thisYear();
    const { from: thisMonthFrom, until: thisMonthUntil } = thisMonth();
    const { from: last7DaysFrom, until: last7DaysUntil } = last7Days();

    if(overallFrom.getTime() === from && overallUntil.getTime() === until) return "overall";
    if(todayFrom.getTime() === from && todayUntil.getTime() === until) return "today";
    if(thisYearFrom.getTime() === from && thisYearUntil.getTime() === until) return "thisYear";
    if(thisMonthFrom.getTime() === from && thisMonthUntil.getTime() === until) return "thisMonth";
    if(last7DaysFrom.getTime() === from && last7DaysUntil.getTime() === until) return "last7Days";

    return "custom";
};

const callFunction: { [K: string]: (shifts: Array<WorkingShift>) => IDatePicker } = {
    yesterday,
    last7Days,
    today,
    thisMonth,
    thisYear,
    overall,
    shift,
    lastShift
};

class DatePicker {
    static of(name: string | Array<Date>, shifts: Array<WorkingShift>) {
        if(name instanceof Array) return {
            from:  new Date(name[0].getTime()),
            until: new Date(name[1].getTime()),
            name:  evaluateType(name[0].getTime(), name[1].getTime())
        };

        if(callFunction[name]) return callFunction[name](shifts);

        throw new Error(`${name} is not a valid DatePicker value. Choose 'last7Days', 'today', 'yesterday', 'thisMonth', 'thisYear' or 'overall'`);
    }
}

export { DatePicker };
