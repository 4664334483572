import PropTypes from "prop-types";
import { getRangeString } from "../../../helper/date";
import { DatePicker } from "../../../types/DatePicker";
import { useRef, useEffect, useState } from "react";

import styles from "./plantOverviewHeader.module.scss";

const getCalendar = calendar => {
    const containsCurrentDate = calendar.name === "today" ||
        calendar.name === "last7Days" ||
        calendar.name === "thisMonth" ||
        calendar.name === "thisYear";

    const finalCalendar = containsCurrentDate ?
        DatePicker.of(calendar.name) :
        calendar;

    return {
        containsCurrentDate,
        ...finalCalendar,
        until: !containsCurrentDate ? finalCalendar.until : new Date(new Date().setUTCHours(new Date().getHours()))
    };
};


const PlantOverviewHeader = ({ ...props }) => { // eslint-disable-line complexity
    const [rangeObject, setRangeObject] = useState(props.options.calendar);
    const rangeObjectRef                = useRef(rangeObject);

    useEffect(() => {
        const calendar = getCalendar(props.options.calendar);

        setRangeObject(calendar);
    }, [props.options.calendar]);

    useEffect(() => {
        rangeObjectRef.current = rangeObject;
    }, [rangeObject]);

    useEffect(() => {
        const interval = setInterval(() => {
            const calendar = getCalendar(rangeObjectRef.current);

            if(calendar.containsCurrentDate) setRangeObject(calendar);
        }, 10000);

        const calendar = getCalendar(rangeObject);

        setRangeObject(calendar);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.plant}>
            {
                props.plant &&
                <div className={styles.logo}>
                    <img src={props.plant?.manufacturer?.logo ?? "/images/Bitmap.png"}/>
                </div>
            }
            <div>
                <div className={styles.plantName}>
                    <span>{props.plant ? props.translate(props.plant?.name) : props.title }</span>
                    <span className={styles.slot}>
                        {(props.options.selectedPlant?.workingShifts && props.options.range === "shift") && `${props.translate("overview.selector.shift")}, `}
                        {(props.options.selectedPlant?.workingShifts && props.options.range === "lastShift") && `${props.translate("overview.selector.last.shift")}, `}
                        {getRangeString([rangeObject.from, rangeObject.until], props.currentLanguage)}
                    </span>
                </div>
                <span className={styles.manufacturer}>{ props.plant?.manufacturer?.name }</span>
            </div>
        </div>
    );
};

PlantOverviewHeader.propTypes = {
    plant:           PropTypes.object,
    options:         PropTypes.object,
    translate:       PropTypes.func,
    currentLanguage: PropTypes.string,
    title:           PropTypes.string
};

export { PlantOverviewHeader };
