import { useState } from "react";
import { Icon, SwitchButton, Button, ButtonGroup, Modal } from "@circle/kip-components";
import { useTranslation } from "@circle/gestalt-app";
import PropTypes from "prop-types";
import { fromFakeUTC, toFakeUTC } from "../../../helper/helper";
import { isActiveShift } from "../../../helper/shiftRange";
import { DatePicker } from "../../../types/DatePicker";
import { DateTimePicker } from "../dateTimePicker/DateTimePicker";

import styles from "./rangeSelector.module.scss";

const RangeSelector = ({ ...props }) => {
    const { translate, currentLanguage } = useTranslation();
    const [isCalendarOpened, setIsCalendarOpened] = useState(false);

    const onCalendarClose = () => {
        setIsCalendarOpened(false);
    };

    const onCalendarSubmit = val => {
        setIsCalendarOpened(false);
        props.onChange(DatePicker.of([fromFakeUTC(val.from.getTime()), fromFakeUTC(val.until.getTime())]));
    };

    const activeShift = props.workingShifts?.find(x => isActiveShift(x));

    const onSelect = e => {
        const getDate = value => {
            if(value === "shift")     return DatePicker.of("shift", props.workingShifts);
            if(value === "lastShift") return DatePicker.of("lastShift", props.workingShifts);
            if(value === "last7Days") return DatePicker.of("last7Days");
            if(value === "today")     return DatePicker.of("today");
            if(value === "yesterday") return DatePicker.of("yesterday");
            if(value === "month")     return DatePicker.of("thisMonth");
            if(value === "year")      return DatePicker.of("thisYear");

            return DatePicker.of("yesterday");
        };

        const getName = value => {
            return value === "shift" ? "shift" : value;
        };

        props.onChange({
            ...getDate(e.target.value),
            name: getName(e.target.value)
        });
    };

    const options = !props.isOverview ? [
        {
            value:   "year",
            checked: props.range === "year",
            label:   translate("overview.selector.year")
        },
        {
            value:   "month",
            checked: props.range === "month",
            label:   translate("overview.selector.month")
        },
        {
            value:   "last7Days",
            checked: props.range === "last7Days",
            label:   translate("overview.selector.last7Days")
        },
        {
            value:   "yesterday",
            checked: props.range === "yesterday",
            label:   translate("overview.selector.yesterday")
        },
        {
            value:   "today",
            checked: props.range === "today",
            label:   translate("overview.selector.today")
        },
        ...(props.workingShifts?.length > 0 ? [
            ...(activeShift ? [
                {
                    value:   "shift",
                    checked: props.range === "shift",
                    label:   translate("overview.selector.shift")
                }
            ] : [])
            ,
            {
                value:   "lastShift",
                checked: props.range === "lastShift",
                label:   translate("overview.selector.last.shift")
            }
        ] : [])
    ] : [
        {
            value:   "year",
            checked: props.range === "year",
            label:   translate("overview.selector.year")
        },
        {
            value:   "month",
            checked: props.range === "month",
            label:   translate("overview.selector.month")
        },
        {
            value:   "last7Days",
            checked: props.range === "last7Days",
            label:   translate("overview.selector.last7Days")
        },
        {
            value:   "yesterday",
            checked: props.range === "yesterday",
            label:   translate("overview.selector.yesterday")
        },
        {
            value:   "today",
            checked: props.range === "today",
            label:   translate("overview.selector.today")
        }
    ];

    return (
        <div>
            <div className={!props.isNested ? styles.selector : styles.container}>
                <ButtonGroup>
                    <Button _variant="icon" onClick={() => setIsCalendarOpened(true)}>
                        <Icon _icon="DatePicker" />
                    </Button>
                </ButtonGroup>
                <SwitchButton
                    onChange={onSelect}
                    options={options}
                />
                <Modal
                    title={translate("modal.date")}
                    isOpen={isCalendarOpened}
                    isCloseable={false}
                    stopBackgroundPropagation={false}
                >
                    <DateTimePicker
                        onChange={onCalendarSubmit}
                        onCancel={onCalendarClose}
                        locale={currentLanguage}
                        value={{
                            from:  toFakeUTC(props.selected.from),
                            until: toFakeUTC(props.selected.until)
                        }}
                    />
                </Modal>
            </div>
            <div className={!activeShift && props.workingShifts?.length > 0 ? styles.noShiftInfo : styles.none}>
                <Icon _icon="Info" />
                {` ${translate("overview.selector.no.shift")} `}
                <strong>{translate("overview.selector.shift")}</strong>
            </div>
        </div>
    );
};

RangeSelector.propTypes = {
    onChange:      PropTypes.func,
    range:         PropTypes.string,
    selected:      PropTypes.object,
    isNested:      PropTypes.bool,
    isOverview:    PropTypes.bool,
    workingShifts: PropTypes.array
};

export { RangeSelector };
