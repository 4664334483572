import { WorkingShift } from "../types/WorkingShift";

const getShiftStamp = (shift: WorkingShift | undefined, forceOverlap = false) => {
    if(!shift) return [];
    const startTime     = new Date(shift.startTime);
    const endTime       = new Date(shift.endTime);
    const isOverlapping = endTime < startTime;
    const date          = new Date();
    const gDate         = new Date();
    const now           = new Date(new Date().setUTCHours(gDate.getHours(), gDate.getMinutes(), gDate.getSeconds(), gDate.getMilliseconds())).getTime();
    const todayStart    = new Date(new Date(new Date(new Date(new Date(date.setUTCDate(date.getUTCDate() + (isOverlapping ? -1 : 0))).setUTCHours(startTime.getUTCHours())).setUTCMinutes(startTime.getUTCMinutes())).setSeconds(0)).setMilliseconds(0));
    const todayEnd      = new Date(new Date(new Date(new Date(new Date(date.setUTCDate(date.getUTCDate() + (isOverlapping ? 1 : 0))).setUTCHours(endTime.getUTCHours())).setUTCMinutes(endTime.getUTCMinutes())).setSeconds(0)).setMilliseconds(0));

    if(todayStart.getTime() < now && !forceOverlap) return [todayStart.getTime(), todayEnd.getTime()];

    return [new Date(todayStart).setUTCDate(todayStart.getUTCDate() - 1), new Date(todayEnd).setUTCDate(todayEnd.getUTCDate() - 1)];
};

export const isActiveShift = (shift: WorkingShift) => {
    const startTime     = new Date(shift.startTime);
    const endTime       = new Date(shift.endTime);
    const isOverlapping = endTime < startTime;
    const date          = new Date();
    const gDate         = new Date();
    const now           = new Date(new Date().setUTCHours(gDate.getHours(), gDate.getMinutes(), gDate.getSeconds(), gDate.getMilliseconds())).getTime();
    const todayStart    = new Date(new Date(new Date(new Date(new Date(new Date(date).setUTCDate(date.getUTCDate() + (isOverlapping ? -1 : 0))).setUTCHours(startTime.getUTCHours())).setUTCMinutes(startTime.getUTCMinutes())).setSeconds(0)).setMilliseconds(0));
    const todayEnd      = new Date(new Date(new Date(new Date(new Date(new Date(date).setUTCDate(date.getUTCDate())).setUTCHours(endTime.getUTCHours())).setUTCMinutes(endTime.getUTCMinutes())).setSeconds(0)).setMilliseconds(0));

    return todayStart.getTime() <= now && now <= todayEnd.getTime();
};

const getLastShift = (shifts: Array<WorkingShift>) => {
    if(shifts.length === 0) return [];

    const gDate = new Date();
    const now   = new Date(new Date().setUTCHours(gDate.getHours(), gDate.getMinutes(), gDate.getSeconds(), gDate.getMilliseconds()));

    const wshifts = [...shifts]
        .map(x => getShiftStamp(x, shifts.length === 1))
        .filter(x => x[1] < now.getTime());

    if(wshifts.length === 0) return [];

    wshifts.sort((a, b) => b[0] - a[0]);
    return [wshifts[0][0], wshifts[0][1]];
};

export const getStartTime = (shifts: Array<WorkingShift>, range: string) => {
    if(range === "lastShift") return getLastShift(shifts);

    const activeShift = shifts.find(x => isActiveShift(x));

    const [startingTime, endingTime] = getShiftStamp(activeShift);

    return [startingTime, endingTime];
};
